import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2'; 
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import GitCheatsheet from './GitCheatsheet.jsx';
import './hostedIndex.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const HostedApp = () => {
  const [selectedCommands, setSelectedCommands] = useState({});
  const [ws, setWs] = useState(null);
  const [connected, setConnected] = useState(false);
  const token = localStorage.getItem('token');
  const [localSelectedCommands, setLocalSelectedCommands] = useState([]);

  useEffect(() => {
    const fetchSelectedCommands = async () => {
      try {
        const response = await fetch('/api/getSelectedCommands');
        const data = await response.json();
        if (typeof data === 'object' && !Array.isArray(data)) {
          setSelectedCommands(data);
        } else {
          setSelectedCommands({});
        }
      } catch (error) {
        console.error('Error fetching selected commands:', error);
      }
    };

    fetchSelectedCommands();
  }, []);

  useEffect(() => {
    if (connected && !ws) {
      const socket = new WebSocket(import.meta.env.VITE_WS_URL);

      socket.onopen = () => {
        console.log('WebSocket connection established');
        socket.send(JSON.stringify({ type: 'gitInitialize' }));
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log('Message from server:', message);
      
        if (message.type === 'updateAnswers') {
          setSelectedCommands(message.data);
        } else if (message.type === 'resetClassNames') {
          setSelectedCommands({});
          setLocalSelectedCommands([]);
        }
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed');
        setWs(null);
        setConnected(false);
      };

      setWs(socket);
    }
  }, [connected, ws]);

  const handleButtonClick = async (commandName) => {
    console.log(selectedCommands);
    try {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: 'selectCommand', commandName }));
      } else {
        await fetch('/api/selectCommand', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ commandName }),
        });
      }
      setLocalSelectedCommands((prevSelected) => [...prevSelected, commandName]);
      setSelectedCommands((prevSelected) => ({
        ...prevSelected,
        [commandName]: (prevSelected[commandName] || 0) + 1,
      }));
    } catch (error) {
      console.error('Error selecting command:', error);
    }
  };

  const handleClearAll = async () => {
    try {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: 'clearAnswers' }));
      } else {
        await fetch('/api/clearCommands', {
          method: 'POST',
        });
        setSelectedCommands({});
        setLocalSelectedCommands([]);
      }
    } catch (error) {
      console.error('Error clearing commands:', error);
    }
  };

  const handleConnect = () => {
    setConnected(true);
  };

  const commandCounts = selectedCommands && typeof selectedCommands === 'object' ? selectedCommands : {};

  const data = {
    labels: Object.keys(commandCounts),
    datasets: [
      {
        label: 'Number of Selections',
        data: Object.values(commandCounts),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: 'y', // Make the bars horizontal
    maintainAspectRatio: false, // Allow the height to expand
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        type: 'category',
      },
    },
  };
  const handleClearMyAnswer = async () => {
    try {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: 'clearMyAnswer', commands: selectedCommands }));
      } else {
        await fetch('/api/clearMyCommand', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ commands: selectedCommands }),
        });
        setSelectedCommands({});
      }
    } catch (error) {
      console.error('Error clearing my answer:', error);
    }
  };


  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div id="git-cheat-sheet">
        <GitCheatsheet onButtonClick={handleButtonClick} selectedCommands={selectedCommands} localSelectedCommands={localSelectedCommands} />
      </div>
      <div id="commands-clicked" style={{ top: '20%', width: '27%', height: '100%', 
          maxHeight: '600px',
          padding: '20px',
        }}>
        <Bar data={data} options={options} />
      </div>
      <button className="button-common connect-btn"onClick={handleConnect}>
        Connect to Git Interactive
      </button>
      {/* <button className="button-common reset-me" onClick={handleClearMyAnswer}>Clear My Answer</button> */}
      {
        token && (
          <button className="button-common reset-all" onClick={handleClearAll}>Clear All Answers</button>
        )
      }
    </div>
  );
};

export default HostedApp;
