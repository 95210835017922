import React, { useRef, useEffect } from 'react';
import { EditorState } from '@codemirror/state';
import { EditorView, lineNumbers } from '@codemirror/view';
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import { css } from '@codemirror/lang-css';
import { keymap } from '@codemirror/view';
import { history, historyKeymap } from '@codemirror/commands';
import { indentWithTab, defaultKeymap } from '@codemirror/commands';
import { highlightActiveLine, highlightSpecialChars } from '@codemirror/view';

const CodeEditor = ({ value, onChange, mode, isTypeScript, id }) => { 
  const editorRef = useRef(null);
  const viewRef = useRef(null);

  const getExtensions = () => {
    switch (mode) {
      case 'html':
        return [html()];
      case 'css':
        return [css()];
      case 'javascript':
        return [javascript({ typescript: isTypeScript })];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (!editorRef.current) return;

    if (!viewRef.current) {
      const state = EditorState.create({
        doc: value || '',
        extensions: [
          keymap.of([indentWithTab, ...defaultKeymap, ...historyKeymap]),
          history(),
          lineNumbers(),
          highlightActiveLine(),
          highlightSpecialChars(),
          ...getExtensions(),
          EditorView.updateListener.of((update) => {
            if (update.docChanged) {
              const newValue = update.state.doc.toString();
              if (onChange) {
                onChange(newValue);
              }
            }
          })
        ],
      });

      viewRef.current = new EditorView({
        state,
        parent: editorRef.current,
      });
    }

    return () => {
      viewRef.current.destroy();
      viewRef.current = null;
    };
  }, [mode, isTypeScript]);

  useEffect(() => {
    if (viewRef.current) {
      const currentValue = viewRef.current.state.doc.toString();
      if (currentValue !== value) {
        viewRef.current.dispatch({
          changes: { from: 0, to: currentValue.length, insert: value },
        });
      }
    }
  }, [value]);

  return (

    <div
      ref={editorRef}
      id={id} 
      className="editor-box"
    />
  );
};

export default CodeEditor;
