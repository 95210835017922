import React, { useState, useEffect } from 'react';
import './ProjectPreview.css';

const ProjectPreviews = () => {
  const [projects, setProjects] = useState([]);
  const numberSet = new Set();

  useEffect(() => {
    const loadFiles = async () => {
      const loadedProjects = [];

      const getRandomNum = () => Math.floor(Math.random() * 38) + 1;

      try {
        
        for (let i = 0; i < 4; i++) {
          let randomNum;
          do {
            randomNum = getRandomNum();
          } while (numberSet.has(randomNum));

          numberSet.add(randomNum);

          
          const htmlModule = await import(`./assets/defaultHtml${randomNum}.js`);
          const { defaultHtml } = htmlModule;

          
          const cssModule = await import(`./assets/defaultCss${randomNum}.js`);
          const { defaultCss } = cssModule;

          
          loadedProjects.push({
            id: i + 1,  
            randomNum,  
            html: defaultHtml,
            css: defaultCss,
            jsPath: `./assets/defaultJs${randomNum}.txt`
          });
        }

       
        const missionHtmlModule = await import(`./assets/missionHtml`);
        const { missionHtml } = missionHtmlModule;

        const missionCssModule = await import(`./assets/missionCss`);
        const { missionCss } = missionCssModule;

        const missionProject = {
          id: 5,  
          randomNum: 'mission',  
          html: missionHtml,
          css: missionCss,
          jsPath: `./assets/missionJs.txt`  
        };

        loadedProjects.push(missionProject);

        setProjects(loadedProjects);
      } catch (error) {
        console.error('Error loading files:', error);
      }
    };

    loadFiles();
  }, []);

  useEffect(() => {
    const overrideEventListeners = () => {
      const originalAddEventListener = EventTarget.prototype.addEventListener;

      EventTarget.prototype.addEventListener = function(type, listener, options) {
        const shouldBePassive = ['touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel'].includes(type);

        if (typeof options === 'object' && options !== null) {
          options.passive = shouldBePassive ? true : options.passive;
        } else if (shouldBePassive) {
          options = { passive: true };
        }

        return originalAddEventListener.call(this, type, listener, options);
      };
    };
    if (document.readyState === 'complete') {
      overrideEventListeners();
    } else {
      window.addEventListener('load', overrideEventListeners);
    }
    
    
  }, []);

  useEffect(() => {
    const loadProjectJS = async () => {
      for (const project of projects) {
        try {
          const jsResponse = await fetch(project.jsPath);
          const jsContent = await jsResponse.text();

          
          const output = document.getElementById(`output-${project.id}`);
          const source = `
            <html>
            <head>
              <script defer src="https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollTrigger.min.js"></script>
              <script defer src="https://cdnjs.cloudflare.com/ajax/libs/sass.js/0.9.2/sass.min.js"></script>
              <script defer src="https://cdn.tailwindcss.com"></script>
              <style>
                body, html {
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  height: 100%;
                  
                }
                ${project.css}
              </style>
            </head>
            <body>
              ${project.html}
              <script type="module">${jsContent}<\/script>
            </body>
            </html>
          `;

          const blob = new Blob([source], { type: 'text/html' });
          const url = URL.createObjectURL(blob);
          output.src = url;
        } catch (error) {
          console.error('Error loading JS:', error);
        }
      }
    };

    if (projects.length > 0) {
      loadProjectJS();
    }
  }, [projects]);

  return (
    <div>
      <div className="project-previews">
        {projects.slice(0, 4).map((project) => (
          <iframe
            key={project.id}
            id={`output-${project.id}`}
            className={`scene-iframe grid-item-${project.id}`}
            title={`Project Preview ${project.randomNum}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectPreviews;
