import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, Suspense } from 'react';

import HomePage from './homepage.jsx';
import Account from './account.jsx';
import Canvas from './Canvas.jsx';
import Navbar from './navbar.jsx';
import LoginModal from './LoginModal';
import Login from './login.jsx'; 
import WildcardUrl from './WildcardUrl.jsx';
import HostedApp from './hosted/HostedApp.jsx';
import CreateTemplate from './hosted/CreateTemplate.jsx';

const App = () => {
  const [loginShow, setLoginShow] = useState(false);

  const handleLoginClick = () => {
    setLoginShow(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setLoginShow(false);
  };
  
  window.addEventListener('message', (event) => {
    if (event.data.type === 'log') {
      window.logViewer.pushLog(event.data.message);  // Capture logs in log viewer
    } else if (event.data.type === 'error') {
      window.logViewer.pushLog(`Error: ${event.data.message}`);  // Capture errors in log viewer
    } else if (event.data.type === 'debug') {
      window.logViewer.pushLog(`Debugger: ${event.data.message}\nStack: ${event.data.stack}`);  // Capture debug logs
    }
  });

  return (
    <Router>
      <Navbar handleLoginClick={handleLoginClick} />
      {loginShow && <LoginModal onClose={() => setLoginShow(false)} />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path='/interactive' element={<CreateTemplate />} />
        <Route path="/git-interactive" element={<HostedApp />} />
        <Route path="/canvas" element={<Canvas handleLoginClick={handleLoginClick} handleLogout={handleLogout} />} />
        <Route 
          path="/account" 
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Account />
            </Suspense>} />
        <Route 
          path="*" 
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <WildcardUrl />
            </Suspense>} />
      </Routes>
    </Router>
  );
};

export default App;
