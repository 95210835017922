import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './canvas.css';
import InputArea from './InputArea';
import LogViewer from './Logviewer';
import CodeEditor from './codeEditor';


const Canvas = ({ handleLoginClick, handleLogout }) => {
  const [isTypeScript, setIsTypeScript] = useState(false);
  const [htmlCode, setHtmlCode] = useState('');
  const [cssCode, setCssCode] = useState('');
  const [jsCode, setJsCode] = useState('');
  const [isTailwindEnabled, setIsTailwindEnabled] = useState(false);
  const [projname, setProjname] = useState('');
  const [projects, setProjects] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isInputAreaVisible, setIsInputAreaVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isSliderAreaVisible, setIsSliderAreaVisible] = useState(false);
  const [is3DViewVisible, setIs3DViewVisible] = useState(false);
  const [sharedLinkVisible, setSharedLinkVisible] = useState(false);
  const [sharedLink, setSharedLink] = useState("");
  const [isAutoRun, setIsAutoRun] = useState(true);
  const [expanded, setExpanded] = useState(null); 
 
  const lastLocalUpdate = useRef(Date.now());
  const sharedProjectTokenRef = useRef(localStorage.getItem('sharedProjectToken'));
  const projectIdRef = useRef(localStorage.getItem('sharedProjectId')); 
  const ws = useRef(null);

  const handleExpand = (editor) => {
    setExpanded(editor); 
  };
  
  const restoreEditors = () => {
    setExpanded(null); 
  };
  

  const toggleSliderArea = () => {
    setIsSliderAreaVisible(!isSliderAreaVisible);
  };

  const toggle3DView = () => {
    setIs3DViewVisible(!is3DViewVisible);
  };

  const saveCurrentProject = async () => {
    const token = localStorage.getItem('token');
    const id = projectIdRef.current || uuidv4(); 
    projectIdRef.current = id; //may cause a problem when new proj is loaded
    

    try {
      const response = await fetch('/api/v1/saveProject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ id, projname, htmlCode, cssCode, jsCode }),
      });

      if (response.status === 403) {
        console.error('No token provided or token is invalid');
      } else {
        const result = await response.json();
        console.log('Project saved:', result);
      }
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  const getProjects = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        handleLoginClick(); // Or redirect to login page
        console.error('User is not logged in');
        return;
      }
  
      const response = await fetch('/api/v1/projects', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const projects = await response.json();
      
      if (Array.isArray(projects)) {
        setProjects(projects);
        setIsPopupVisible(true);
      } else {
        console.error('Invalid projects data');
      }
      
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const loadProject = (project) => {
    setSelectedProject(project);
    setIsConfirmVisible(true);
  };

  const confirmLoadProject = () => {
    setHtmlCode('');
    setCssCode('');
    setJsCode('');

    if (selectedProject) {
      setHtmlCode(selectedProject.html);
      setCssCode(selectedProject.css);
      setJsCode(selectedProject.js);
      projectIdRef.current = selectedProject.id;
    }

    setIsConfirmVisible(false);
    setIsPopupVisible(false);
  };

  const cancelLoadProject = () => {
    setIsConfirmVisible(false);
    setSelectedProject(null);
  };

  const generateShareableLink = async () => {
    const id = projectIdRef.current || uuidv4(); 
    projectIdRef.current = id;

    try {
      const response = await fetch('/api/v1/generateShareableLink', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ projectId: id }),
      });
      if (!response.ok) {
        throw new Error('Did not generate link properly');
      }
  
      const { token } = await response.json();
      const shareableLink = `${window.location.origin}/canvas?project=${id}&token=${token}`;
      setSharedLink(shareableLink);
      navigator.clipboard.writeText(shareableLink)
      localStorage.setItem('sharedProjectToken', token);
      localStorage.setItem('sharedProjectId', id);
      projectIdRef.current = id;
      sharedProjectTokenRef.current = token;
  
      if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
        ws.current.close();
      }
      ws.current = new WebSocket(import.meta.env.VITE_WS_URL);
      handleWebSocketMessages(ws.current);

      ws.current.onopen = () => {
        const initialMessage = {
          type: 'initialize',
          projectId: id,
          sharedProjectToken: token
        };
        ws.current.send(JSON.stringify(initialMessage)); 
      };
  
    } catch (error) {
      console.error('Error generating shareable link:', error);
    }
  }
  
  //this handles the reload page or when someone comes to the page(not for when already connected)
  useEffect(() => {
    const init = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('project') || localStorage.getItem('sharedProjectId');
      const token = urlParams.get('token') || localStorage.getItem('sharedProjectToken');

      if(id && token) {
        localStorage.setItem('sharedProjectId', id);
        localStorage.setItem('sharedProjectToken', token)
        projectIdRef.current = id;
        sharedProjectTokenRef.current = token;
        console.log(`Load project with ID: ${id}`);

        if (!ws.current || ws.current.readyState === WebSocket.CLOSED) {
          ws.current = new WebSocket(import.meta.env.VITE_WS_URL);
          handleWebSocketMessages(ws.current);
    
          ws.current.onopen = () => {
            const initialMessage = {
              type: 'initialize',
              projectId: id, 
              sharedProjectToken: token,
            };
            ws.current.send(JSON.stringify(initialMessage));
          };
        }
        } else {
          console.log('No valid project or token found in URL or localStorage');
        }
      };
    init();
    return () => {
      if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
        ws.current.close();
      }
    };
  }, []);
  
  let reconnectAttempts = 0;
  const maxReconnectAttempts = 3;
  const reconnectWebSocket = () => {
    if (reconnectAttempts >= maxReconnectAttempts) {
      console.log('Max reconnection attempts reached. Please refresh page.');
      return;
    }
    reconnectAttempts += 1;
    setTimeout(() => {
      console.log(`Attempting to reconnect WebSocket... (${reconnectAttempts}/${maxReconnectAttempts})`);

      ws.current = new WebSocket(import.meta.env.VITE_WS_URL);
      handleWebSocketMessages(ws.current);

      ws.current.onopen = () => {
        console.log('WebSocket reconnected');
        reconnectAttempts = 0;  // Reset the attempt counter after a successful connection
        const initialMessage = {
          type: 'initialize',
          projectId: projectIdRef.current,
          sharedProjectToken: sharedProjectTokenRef.current,
        };
        ws.current.send(JSON.stringify(initialMessage));
      };
      ws.current.onclose = () => {
        console.log('WebSocket closed again, attempting to reconnect...');
      };
      ws.current.onerror = (error) => {
        console.log('WebSocket error:', error);
      };
      console.log('Reconnected with:', ws.current);
    }, 5000);
  };


  let lastSentUpdateTime = 0; 
  const throttleTime = 300;   // Currently throttled, if its too much change this.
  let debounceTimer;

  const batchUpdate = (field, value) => {
    const now = Date.now();
    
    if (now - lastSentUpdateTime > throttleTime) {
      lastSentUpdateTime = now;

      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        const message = {
          type: 'batchUpdate',
          updates: [{ field, value, timestamp: lastLocalUpdate.current }],  
          projectId: projectIdRef.current,
          sharedProjectToken: sharedProjectTokenRef.current,
        };
        ws.current.send(JSON.stringify(message));
      
      }
    } 
  };

  const handleChange = (field, value) => {
    lastLocalUpdate.current = Date.now(); 
    
    if (field === 'htmlCode') setHtmlCode(value);
    if (field === 'cssCode') setCssCode(value);
    if (field === 'jsCode') setJsCode(value);

    batchUpdate(field, value);  

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      batchUpdate(field, value);  
    }, 300);  
  };
    
  const handleWebSocketMessages = (wsInstance) => {
    wsInstance.onmessage = (event) => {
      const message = JSON.parse(event.data);
  
      if (message.type === 'batchUpdate' && message.projectId === projectIdRef.current) {
        message.updates.forEach((update) => {
          let { field, value, timestamp } = update;
  
          if (timestamp > lastLocalUpdate.current) {
            if (field === 'htmlCode') setHtmlCode(value);
            if (field === 'cssCode') setCssCode(value);
            if (field === 'jsCode') setJsCode(value);
          }
        });
      }
    };
  
    wsInstance.onclose = () => {
      console.log('WebSocket disconnected');
      reconnectWebSocket();
    };
  };

  

//this is the refresh main random load
  useEffect(() => {
    const loadFiles = async() => {
      const randomNum = Math.floor(Math.random() * 38) + 1;

      try {
        const htmlModule = await import(`./assets/defaultHtml${randomNum}.js`);
        const { defaultHtml } = htmlModule;
        setHtmlCode(defaultHtml);

        const cssModule = await import(`./assets/defaultCss${randomNum}.js`);
        const { defaultCss } = cssModule;
        setCssCode(defaultCss);

        const jsResponse = await fetch(`./assets/defaultJs${randomNum}.txt`);
        const jsContent = await jsResponse.text();
        setJsCode(jsContent);
      } catch (error) {
        console.error('Error loading files:', error);
      }
    }

    loadFiles();
  }, []);

  const renderOutput = () => {
    const output = document.getElementById("output");

    let jsContent = jsCode;
    if (isTypeScript) {
      jsContent = window.ts.transpile(jsCode);
    }

    const tailwindScript = isTailwindEnabled
      ? `<script defer src="https://cdn.tailwindcss.com"></script>`
      : "";

    const source = `
      <html>
      <head>
        <script defer src="https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollTrigger.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/typescript@latest"></script>
        <script defer src="https://cdnjs.cloudflare.com/ajax/libs/sass.js/0.9.2/sass.min.js"></script>
        <script defer src="https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/gsap.min.js"></script>
        <script defer src="https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollTrigger.min.js"></script>
        ${tailwindScript}
        <style>${cssCode}</style>
      </head>
      <body>
        ${htmlCode}
        <script>
          console.log = function(...args) {
            window.parent.postMessage({ type: 'log', message: args.join(' ') }, '*');
          };
  
          window.onerror = function(message, source, lineno, colno, error) {
            window.parent.postMessage({ type: 'error', message: \`\${message} at \${source}:\${lineno}:\${colno}\` }, '*');
          };
    
          try {
          ${jsContent}
          } catch (error) {
            window.parent.postMessage({ type: 'debug', message: \`Debug: \${error.message}\`, stack: error.stack }, '*');
          }
       <\/script>
    </body>
    </html>
  `;
    

    const blob = new Blob([source], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    output.src = url;
  };

  useEffect(() => {
    if(isAutoRun) {
      renderOutput();
    }
  }, [htmlCode, cssCode, jsCode, isTypeScript, isTailwindEnabled, isAutoRun]);

  useEffect(() => {
    const listItems = document.querySelectorAll(".sidebar-menu li");

    listItems.forEach((listItem) => {
      listItem.addEventListener("click", () => {
        listItems.forEach((otherItem) => {
          otherItem.classList.remove('active');
        });
        listItem.classList.add('active');
      });
    });
  }, []);

  const handleQuerySubmit = async (query) => {
    setMessages((prevMessages) => [...prevMessages, { sender: 'user', text: query }]);

    try {
      const response = await fetch('/generate-css', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userQuery: query }),
      });

      const data = await response.json();
      console.log('API Response:', data);

      const parsed = data.response;

      setMessages((prevMessages) => [...prevMessages, { sender: 'ai', text: data.cssCode }]);

    } catch (error) {
      console.error('Error generating CSS:', error);
      setMessages((prevMessages) => [...prevMessages, { sender: 'ai', text: 'Error generating response' }]);
    }
  };
  
  const toggleInputArea = () => {
    setIsInputAreaVisible(!isInputAreaVisible);
  };


  const handleCreateNewProject = () => {
    const newProjectId = uuidv4(); 
    const newToken = uuidv4(); 
    
  
    
    console.log('Creating new project:');
    console.log('New Project ID:', newProjectId);
    console.log('New Token ID:', newToken);
  
    
    saveCurrentProject();
  
    
    sharedProjectTokenRef.current = newToken;
    projectIdRef.current = newProjectId;
    setHtmlCode(''); 
    setCssCode('');  
    setJsCode('');   
    setProjname(''); 
    

  };
  

  return (
    <div>
      
        {isInputAreaVisible && (
        
        <div className="input-area-and-conversation-container">
          <InputArea onSubmit={handleQuerySubmit} />
          <ConversationView messages={messages} />
          </div>
      )}
        
      <nav className="canvas-sidebar-menu">
        <ul>
          <li>
            <a href="/">
              <i className="fa fa-home nav-icon"></i>
              <span className="nav-text">Home</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={handleCreateNewProject}>
              <i className="fa fa-image nav-icon"></i>
              <span className="nav-text">New Project</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={saveCurrentProject}>
              <i className="fa fa-save nav-icon"></i>
              <span className="nav-text">Save Current</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={getProjects}>
              <i className="fa fa-folder-open nav-icon"></i>
              <span className="nav-text">My Projects</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-bell nav-icon"></i>
              <span className="nav-text">Notification</span>
            </a>
          </li>
        </ul>

        <ul className="logout">
          <li>
            <a href="/account">
              <i className="fa fa-cogs nav-icon"></i>
              <span className="nav-text">Settings</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              <i className="fa fa-right-from-bracket nav-icon"></i>
              <span className="nav-text">Logout</span>
            </a>
          </li>
        </ul>
      </nav>

      {isPopupVisible && (
        <div className="popup-menu">
        <h3>Select a Project</h3>
        <ul>
          {projects.map((project) => (
            <li key={project.id} onClick={() => loadProject(project)}>
              {project.projname}
            </li>
          ))}
        </ul>
        <button onClick={() => setIsPopupVisible(false)}>Close</button>
      </div>
    )}

    {isConfirmVisible && (
      <div className="confirmation-dialog">
        <p>Are you sure you want to load your project? Any unsaved changes on the current canvas will be deleted.</p>
        <button onClick={confirmLoadProject}>Load Project</button>
        <button onClick={cancelLoadProject}>Cancel</button>
      </div>
    )}

    <div className="animated-background">
      <div className="waves"></div>
      
      <div className="container">
      <div className='envelope-containers'>
      <button className={`button-common envelope-tab ${isInputAreaVisible ? 'active' : ''}`} onClick={toggleInputArea}>
        Click Here To Ask AI
        </button>
      <button
       className={`button-common generate-share envelope-tab ${sharedLinkVisible ? 'active' : ''}`} 
       onClick={() => setSharedLinkVisible(true)}>
      {sharedLinkVisible ? 'Start/Stop/Copy Link' : 'Invite Others To Collab'}
      </button>
      <LogViewer />
      <div className="switch-div envelop-tab">
        <label className="switch">
          
            <input type="checkbox" checked={isAutoRun} onChange={() => setIsAutoRun(!isAutoRun)}
          />
          <span className="slider round"></span>
          </label>
        </div>
        <button onClick={isAutoRun ? null : renderOutput} 
        className={`button-common run-button ${isAutoRun ? 'disabled' : ''}`}
        >Run Code{isAutoRun ? '' : ''}
        </button>
      </div>

      {sharedLinkVisible && (
        <div className="popup log-popup">
          <div className="popup-content">
            <h3>Shareable Link</h3>
            <button className="close-console" onClick={() => navigator.clipboard.writeText(sharedLink)}>
              Copy Link
            </button>
            <h3>{sharedLink}</h3>
            <button className="close-console" onClick={() => {
              localStorage.removeItem('sharedProjectToken');
              generateShareableLink();
            }}>Start New Session
              </button>

            <button className="close-console" onClick={() => {
              localStorage.removeItem('sharedProjectToken');
              sharedProjectTokenRef.current = null;
              setSharedLinkVisible(false);
              if(ws.current){
                ws.current.close();
              }
            }}>
              End Sharing Session
            </button>
            <button className="close-console" onClick={() => setSharedLinkVisible(false)}>Close</button>
          </div>
        </div>
      )}

<div className="leftside">
            <label>
              Project Name:
              <input 
                type="text" 
                value={projname} 
                onChange={(e) => setProjname(e.target.value)} 
                placeholder="Enter project name" 
                />
            </label>
  <div className={`editor-box ${expanded === 'html' ? 'expanded' : 'collapsed'}`} id="html-editor">
    <label>
      <i className="fa-brands fa-html5"></i> HTML
      <button onClick={() => navigator.clipboard.writeText(htmlCode)} className="copy-button">
        <i className="fa-regular fa-copy"></i>
      </button>
    </label>
    <CodeEditor 
      id="html-code"
      value={htmlCode}
      onChange={(value) => handleChange('htmlCode', value)}
      mode="html"  
    />

    <button className="button-common expand-btn" onClick={() => handleExpand('html')}>Expand</button>
  </div>

  <div className={`editor-box ${expanded === 'css' ? 'expanded' : 'collapsed'}`} id="css-editor">
    <label>
      <i className="fa-brands fa-css3-alt"></i> CSS
      <button onClick={() => navigator.clipboard.writeText(cssCode)} className="copy-button">
        <i className="fa-regular fa-copy"></i>
      </button>
    </label>
    <CodeEditor
      id="css-code"
      
      value={cssCode}
      onChange={(value) => handleChange('cssCode', value)}
      mode="css"
    />
    <label>
      <input
        type="checkbox"
        checked={isTailwindEnabled}
        onChange={() => setIsTailwindEnabled(!isTailwindEnabled)}
      />
      Enable Tailwind
    </label>
    <button className="button-common expand-btn" onClick={() => handleExpand('css')}>Expand</button>
  </div>

  <div className={`editor-box ${expanded === 'js' ? 'expanded' : 'collapsed'}`} id="js-editor">
    <label>
      <i className="fa-brands fa-js"></i> JS/TS
      <button onClick={() => navigator.clipboard.writeText(jsCode)} className="copy-button">
        <i className="fa-regular fa-copy"></i>
      </button>
    </label>
    <CodeEditor
      id="js-code"
      value={jsCode}
      onChange={(value) => handleChange('jsCode', value)}
      mode="javascript"
      isTypeScript={isTypeScript}  
    />
    <div>
      <label>
        <input
          type="checkbox"
          checked={isTypeScript}
          onChange={() => setIsTypeScript(!isTypeScript)}
        />
        Use TypeScript
      </label>
    </div>
    <button className="button-common expand-btn" onClick={() => handleExpand('js')}>Expand</button>
  </div>

  <button className="button-common restore-btn" onClick={restoreEditors}>Restore All</button>
</div>


        <div className="rightside">
          <label><i className="fa-solid fa-play"></i> Output</label>
          <iframe id="output"></iframe>
        </div>
      </div>
  </div>
</div>
  );
}

const ConversationView = ({ messages }) => {
  return (
    <div className="conversation-view">
      {messages.map((message, index) => (
        <div key={index} className={`message ${message.sender}`}>
          <strong>{message.sender === 'user' ? 'You' : 'AI'}:</strong> {message.text}
        </div>
      ))}
    </div>
  );
};

export default Canvas;