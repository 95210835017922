import { Link, useNavigate } from 'react-router-dom';
import './navbar.css';
import { useState } from 'react';
import LoginModal from './LoginModal'; 

const Navbar = () => {
  const [loginShow, setLoginShow] = useState(false);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    setLoginShow(true);
  };

  const handleCloseModal = () => {
    setLoginShow(false);
  };

  const handleCreateTemplateClick = () => {
    navigate('/interactive');
  }
  const handleGitClick = () => {
    navigate('/git-interactive');
  }

  const handleAccountClick = async (e) => {
    e.preventDefault(); 

    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch('/api/v1/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        console.log(result);
        if (response.ok && result.isValid) {
          navigate('/account'); 
        } else {
          handleLoginClick(); 
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        handleLoginClick(); 
      }
    } else {
      handleLoginClick(); 
    }
  };

  return (
    <>
      <div id='nav-container'>
        <nav className="navbar">
          <div className="navbar-logo">
            <Link to="/home">
              <img height='65px' width='65px' src='./assets/plogo.png' alt='Logo' />
            </Link>
          </div>
          <div>
            <button className='nav-create-button'>
              <Link to="/canvas">Create</Link>
            </button>
          </div>
          <div className="navbar-links">
            <button className='interactive-button close-console' onClick={handleCreateTemplateClick}>Interactive Creator</button>
            <button className="interactive-button close-console" onClick={handleGitClick}>Git-Interactive</button>
            <button className='interactive-button close-console' onClick={handleAccountClick}>
              Account
            </button>
            <button className="interactive-button close-console" onClick={handleLoginClick}>
              Login
            </button>
          </div>
        </nav>
      </div>

      {loginShow && <LoginModal onClose={handleCloseModal} />}
    </>
  );
};

export default Navbar;
