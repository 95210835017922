import React, { useState } from 'react';

const FlashcardGenerator = ({ sections }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  const nextCard = () => {
    setShowAnswer(false);
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % sections.flatMap(section => section.commands).length);
  };

  const cards = sections.flatMap(section => section.commands);
  const currentCard = cards[currentCardIndex];

  return (
    <div className="flashcard-container">
      {currentCard ? (
        <div className="flashcard">
          <div className="flashcard-question">
            {showAnswer ? currentCard.description : currentCard.name}
          </div>
          <button onClick={() => setShowAnswer(!showAnswer)}>
            {showAnswer ? "Show Command" : "Show Description"}
          </button>
          <button onClick={nextCard}>Next Card</button>
        </div>
      ) : (
        <p>No flashcards available. Please add some commands first.</p>
      )}
    </div>
  );
};

export default FlashcardGenerator ;
