import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';


ReactDOM.createRoot(document.getElementById('root')).render(<App />);


(function() {
  const originalAddEventListener = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function(type, listener, options) {
    
    const shouldBePassive = ['touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel'].includes(type);

    if (typeof options === 'object' && options !== null) {
      options.passive = shouldBePassive ? true : options.passive;
    } else if (shouldBePassive) {  
      options = { passive: true };
    }
    originalAddEventListener.call(this, type, listener, options);
  };
})();
