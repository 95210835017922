import Navbar from './navbar'; 
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import "./homepage.css";
import React from 'react';

import './ProjectPreview.css'
import ProjectPreviews from './ProjectPreview';

const HomePage = () => {

  const navigate = useNavigate();
  

  useEffect(() => {
    const interBubble = document.querySelector(".interactive");
    if (!interBubble) return;
  
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;
  
    const move = () => {
      curX += (tgX - curX) / 20;
      curY += (tgY - curY) / 20;
      if (interBubble) {
        interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
      }
      requestAnimationFrame(move);
    };
  
    const handleMouseMove = (event) => {
      tgX = event.clientX;
      tgY = event.clientY;
    };
  
    window.addEventListener("mousemove", handleMouseMove);
    move();
  
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleStartCreating = () => {
    navigate('/canvas');
  }

  return (
    <>
      <div className="card">
     
    
        <div className="content">
          <h1>Press CSS</h1>
          <p>Mission is to bridge the gap between creation and imagination by merging machine learning with web design. We transform user inputs into fully stylized HTML, CSS, and JavaScript, delivering ready-to-use code. With in-browser editing, users can fine-tune projects to bring their creative visions to life. Press CSS makes web design more accessible, efficient, and innovative.</p>
        </div>
      </div>
      <div className="gradient-bg">
      <div className="gradient-bg">
  
        
  <svg xmlns="https://www.w3.org/2000/svg" className="svgBlur">
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
        <feBlend in="SourceGraphic" in2="goo" />
      </filter>
    </defs>
  </svg>
        <div className="gradients-container">
          <div className="g1"></div>
          <div className="g2"></div>
          <div className="g3"></div>
          <div className="g4"></div>
          <div className="g5"></div>
          <div className="interactive"></div>
        </div>
      </div>
      <div>
        <Navbar />
      </div>
      <div>
        <ProjectPreviews />
      </div>
      <button className='create-button' onClick={handleStartCreating}>Start Creating</button>
    </div>
    </>
    
  );
};

export default HomePage;
