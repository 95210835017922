import './loginmodal.css';
import Login from './login';
import Register from './register.jsx';
import React, { useState, useEffect } from 'react'; 

const LoginModal = ({ onClose }) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token); 
    };

    checkToken(); 

   
    window.addEventListener('storage', checkToken);

    return () => {
      window.removeEventListener('storage', checkToken); 
    };
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {isRegistering ? <Register /> : <Login />}
        <button className="close-button" onClick={onClose}>X</button>

        {!isLoggedIn && (
          <div className="toggle-auth">
            {isRegistering ? (
              <button onClick={() => setIsRegistering(false)}>
                Already have an account? Log in here
              </button>
            ) : (
              <button onClick={() => setIsRegistering(true)}>
                Not a user? Register now
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
