import './App.css';
import React, { useState } from 'react';

const InputArea = ({ onSubmit }) => {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(query);
  };


  return (
    <>
    
    <div className="form-container">

    <form onSubmit={handleSubmit} style={{ textAlign: 'center', margin: '20px' }}>
      <input 
      className='query-box'
      type="text" 
      value={query} 
      onChange={(e) => setQuery(e.target.value)} 
      placeholder="Enter idea and Press CSS" 
      style={{ width: '300px', padding: '10px' }}
      />
      <button type="submit" className="glass">CSS</button>
    </form>
    </div>
    </>
  );
};

export default InputArea;
