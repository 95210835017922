import React, { useState } from 'react';
import './hostedIndex.css';
import FlashcardGenerator from './FlashcardGenerator';

const CreateTemplate = () => {
  const [sections, setSections] = useState([]);
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [newCommandName, setNewCommandName] = useState("");
  const [newCommandDescription, setNewCommandDescription] = useState("");
  const [selectedSectionIndex, setSelectedSectionIndex] = useState('');
  const [templateType, setTemplateType] = useState("cheatsheet");
  const [editingSectionIndex, setEditingSectionIndex] = useState(null);
  const [aiPrompt, setAiPrompt] = useState("");
  const [templateName, setTemplateName] = useState('');

  const addSection = () => {
    if (newSectionTitle.trim()) {
      setSections([...sections, { title: newSectionTitle, commands: [] }]);
      setNewSectionTitle("");
    }
  };

  const addCommand = () => {
    if (selectedSectionIndex !== null && newCommandName.trim() && newCommandDescription.trim()) {
      const updatedSections = [...sections];
      updatedSections[selectedSectionIndex].commands.push({
        name: newCommandName,
        description: newCommandDescription,
      });
      setSections(updatedSections);
      setNewCommandName("");
      setNewCommandDescription("");
    }
  };

  const editSectionTitle = (index, newTitle) => {
    const updatedSections = [...sections];
    updatedSections[index].title = newTitle;
    setSections(updatedSections);
  };

  const editCommand = (sectionIndex, commandIndex, newName, newDescription) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].commands[commandIndex] = {
      name: newName,
      description: newDescription,
    };
    setSections(updatedSections);
  };

  const deleteCommand = (sectionIndex, commandIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].commands.splice(commandIndex, 1);
    setSections(updatedSections);
  };

  const deleteSection = (index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };

  const handleAiPromptSubmit = async () => {
    try {
      const response = await fetch('https://interactive-generate.aripine93.workers.dev/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userInput: aiPrompt }),
      });
  
      // Fetch the response as text to handle both valid and invalid JSON
      let responseText = await response.text();
      let result;
  
      // Try parsing the responseText as JSON
      try {
        result = JSON.parse(responseText);
      } catch (e) {
        // If parsing fails, preprocess the responseText
        console.warn('Initial JSON.parse failed, preprocessing the responseText.');
        const processedText = preprocessResponse(responseText);
  
        try {
          result = JSON.parse(processedText);
        } catch (parseError) {
          console.error('Failed to parse the preprocessed response as JSON:', parseError);
          return; // Exit if parsing fails
        }
      }
      setSections([...sections, result]);
    } catch (err) {
      console.error('Trouble with the AI:', err);
    }
    setAiPrompt('');
  };
  
  // Function to preprocess the response string
  function preprocessResponse(responseText) {
    let processedText = responseText;
  
    // Replace single quotes with double quotes
    processedText = processedText.replace(/'/g, '"');
  
    // Add double quotes around unquoted keys
    processedText = processedText.replace(/(\b\w+\b)\s*:/g, '"$1":');
  
    // Remove trailing commas
    processedText = processedText.replace(/,(\s*[}\]])/g, '$1');
  
    return processedText;
  }
  
  

  const handleSubmit = async() => {
    console.log(`Final Template: ${templateName} `, sections);
    const token = localStorage.getItem('token');
    const bodyData = { templateName, sections: JSON.stringify(sections) }
    const saveData = await fetch('/api/v1/users/templates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData)
    })
    if(!saveData.ok){
      console.log('error saving:', saveData);
      return;
    }
    console.log('Current template saved!', saveData);
  };

  return (
    <div>
      <header>
        <h1>{templateType === "cheatsheet" ? "Cheatsheet Generator" : "Flashcard Generator"}</h1>
        <p>Create and customize your own {templateType === "cheatsheet" ? "cheatsheet" : "flashcards"}.</p>
      </header>

      <div className="template-type-selector">
        <label>
          <input
            type="radio"
            value="cheatsheet"
            checked={templateType === "cheatsheet"}
            onChange={() => setTemplateType("cheatsheet")}
          />
          Cheatsheet
        </label>
        <label>
          <input
            type="radio"
            value="flashcards"
            checked={templateType === "flashcards"}
            onChange={() => setTemplateType("flashcards")}
          />
          Flashcards
        </label>
      </div>

      {templateType === "cheatsheet" && (
        <>
        <div className='template-name'>
          <input
          type="text"
          placeholder='Template Name (cannot be empty)'
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          />
        </div>
          <div className="add-section">
            <input
              type="text"
              placeholder="New Section Title"
              value={newSectionTitle}
              onChange={(e) => setNewSectionTitle(e.target.value)}
            />
            <button onClick={addSection}>Add Section</button>
          </div>

          <div className="add-section">
            <input
              id="ai-input"
              type="text"
              placeholder="Write a topic and we will create a section for you! (git commands, data structures in js, cli commands,  etc..)"
              value={aiPrompt}
              onChange={(e) => setAiPrompt(e.target.value)}
            />
            <button onClick={handleAiPromptSubmit}>Generate with AI</button>
          </div>

          <div className="add-command">
            <select
              value={selectedSectionIndex}
              onChange={(e) => setSelectedSectionIndex(e.target.value)}
            >
              <option value={''}>Select Section</option>
              {sections.map((section, index) => (
                <option key={index} value={index}>
                  {section.title}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="New Command Name"
              value={newCommandName}
              onChange={(e) => setNewCommandName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Command Description"
              value={newCommandDescription}
              onChange={(e) => setNewCommandDescription(e.target.value)}
            />
            <button onClick={addCommand}>Add Command</button>
          </div>

          {sections.map((section, index) => (
            <div className="git-section" key={index}>
              {editingSectionIndex === index ? (
                <div className="edit-section">
                  <input
                    type="text"
                    value={section.title}
                    onChange={(e) => editSectionTitle(index, e.target.value)}
                    className="section-title-input"
                  />
                  {section.commands.map((command, idx) => (
                    <div className="command" key={idx}>
                      <input
                        type="text"
                        value={command.name}
                        onChange={(e) =>
                          editCommand(index, idx, e.target.value, command.description)
                        }
                        className="command-name-input"
                      />
                      <textarea
                        value={command.description}
                        onChange={(e) =>
                          editCommand(index, idx, command.name, e.target.value)
                        }
                        className="command-description-input"
                      />
                      <button onClick={() => deleteCommand(index, idx)}>Delete Command</button>
                    </div>
                  ))}
                  <button onClick={() => setEditingSectionIndex(null)}>Save Changes</button>
                  <button onClick={() => deleteSection(index)}>Delete Section</button>
                </div>
              ) : (
                <>
                  <h2>{section.title}</h2>
                  {section && section.commands && Array.isArray(section.commands) && (
                    section.commands.map((command, idx) => (
                      <div className="command" key={idx}>
                        <span className="command-name">{command.name}</span>
                        <p>{command.description}</p>
                      </div>
                    ))
                  )}
                  <button className="submit-button" onClick={() => setEditingSectionIndex(index)}>Edit Section</button>
                </>

              )}
            </div>
          ))}
        </>
      )}

      {templateType === "flashcards" && <FlashcardGenerator sections={sections} />}

      <button onClick={handleSubmit} className="submit-button">Submit Template</button>
    </div>
  );
};


export default CreateTemplate;



